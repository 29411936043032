import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { get, post, del } from '@common-src/service/request';
// import CAMERA_TREE_DATA from '@common-src/json/camera-tree-mock.json';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem`;
class CameraPlayService {
    /**
     * 查询设备树
     * @returns
     */
    async getCameraTreeData() {
        // return new Promise((res) => {
        //     setTimeout(() => {
        //         res(CAMERA_TREE_DATA.items);
        //     }, 2000);
        // });
        const res = await get(`${URL_PATH}/facility/locationFacilityList`);
        // return res;
        return _.map(res, item => {
            return new CameraLocationTreeEntityModel().toModel(Object.assign({ selectable: false }, item));
        });
    }

    async getCameraGroupTreeData() {
        const res = await get(`${URL_PATH}/view/group/query`);
        return _.map(res, item => {
            return {
                id: item.groupId,
                name: item.groupName,
                children: _.map(item.viewList, child => ({ id: child.id, name: child.name, groupId: item.groupId, layout: child.layout }))
            };
        });
    }

    async saveGroup(params) {
        const res = await post(`${URL_PATH}/view/group/save`, params);
        return res;
    }

    async saveGroupView(params) {
        const res = await post(`${URL_PATH}/view/save`, params);
        return res;
    }

    async saveGroupViewRelation(viewId: string, facilityIds: Array<any>, layout) {
        const res = await post(`${URL_PATH}/view/saveRel`, { viewId, facilityIds, layout });
        return res;
    }

    async deleteGroup(id: string) {
        const res = await del(`${URL_PATH}/view/group/${id}`);
        return res;
    }

    async deleteGroupView(id: string) {
        const res = await del(`${URL_PATH}/view/${id}`);
        return res;
    }

    async getFacilityAttrs(facilityId: string) {
        const res = await get(`${URL_PATH}/view/facility/getAttrs/${facilityId}`);
        return res;
    }

    async getFacilityAttrsByViewId(viewId: string) {
        const res = await get(`${URL_PATH}/view/getAttrs/${viewId}`);
        return res;
    }
}

export class CameraLocationTreeEntityModel extends SimpleTreeModel {
    type: string = undefined;

    toModel(json): any {
        this.type = _.get(json, 'type');
        this.title = _.get(json, 'name');
        this.key = this.value = this.id = _.get(json, 'id');
        this.pId = _.get(json, 'parentId');
        this.sort = _.get(json, 'sort');
        if (!_.isUndefined(_.get(json, 'selectable'))) {
            this.selectable = !!_.get(json, 'selectable');
            // this.disabled = !this.selectable;
        }
        if (!_.isUndefined(_.get(json, 'visible'))) {
            this.visible = _.get(json, 'visible') === 'visible';
        }
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children, item => {
                item.parentId = this.id;
                const child: CameraLocationTreeEntityModel = new CameraLocationTreeEntityModel().toModel(item);
                child.parent = this;
                return child;
            });
        } else {
            this.children = [];
        }
        return this;
        // return _.toPlainObject(this);
    }
}

export default new CameraPlayService();
